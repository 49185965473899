
import { defineComponent, onMounted, ref, computed, toRefs } from "vue";
import NotificationService from "@/core/services/car/NotificationService";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import InfiniteScroll from "infinite-loading-vue3";

export default defineComponent({
  name: "lt-notification",
  components: { InfiniteScroll },
  props: {
    notificationList: Array,
    paginationDetails: Array,
    notificationCount: String,
  },

  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    let page = ref<any>(1);
    let enableNotificationCall = ref<boolean>(true);
    // const paginationDetails = computed(() => {
    //   console.log('props.notifications',props.notifications)
    //   return props.notifications ? props.notifications.paginationDetails : [];
    // });
    const { notificationList, paginationDetails, notificationCount } =
      toRefs(props);
    // let paginationDetails = ref<any>();
    // let notificationList = ref<Array<any>>();
    // let notifications = ref<any>();
    let noResult = ref<boolean>(false);
    let message = ref<any>();

    const user = computed(() => {
      return store.getters.currentUser;
    });

    let baseUrl = ref<string>("");
    onMounted(() => {
      // page.value = page.value + 1;
      // baseUrl.value = process.env.VUE_APP_BASE_URL;
    });

    // const getRecentNotification = () => {
    //     Promise.all([
    //       NotificationService.getNotificationList({ per_page: 10 }, page.value),
    //     ]).then((data) => {
    //
    //       notificationList.value = store.getters.getNotification;
    //       paginationDetails.value = store.getters.getNotificationPaginationObj;
    //       console.log(paginationDetails.value?.links.length, "--------");
    //     });
    // }

    const loadDataFromServer = () => {
      console.log(enableNotificationCall.value);
      console.log(paginationDetails);
      //&&paginationDetails.value?.links.length > page.value
      if (enableNotificationCall.value) {
        page.value = page.value + 1;
        enableNotificationCall.value = false;
        Promise.all([
          NotificationService.getNotificationList({ per_page: 10 }, page.value),
        ]).then((data) => {
          console.log(page.value);
          enableNotificationCall.value = true;
          if (
            store.getters.getNotification &&
            store.getters.getNotification.length > 0
          ) {
            console.log("notificationList", notificationList.value);

            for (let key in store.getters.getNotification) {
              notificationList?.value?.push(store.getters.getNotification[key]);
            }

            paginationDetails.value =
              store.getters.getNotificationPaginationObj;
          } else {
            noResult.value = true;
            message.value = "No result found";
          }
        });
      }

      console.log(
        "----------------------------------------------------------------innnnnnnnn"
      );
    };
    // NotificationService.makeNotificationRead(),
    const makeNotificationRead = () => {
      Promise.all([NotificationService.makeNotificationRead()]).then((data) => {
        notificationCount.value = store.getters.getUnreadNotificationCount;
      });
    };

    const notification_close_button = ref();
    const sendToNotificationPage = () => {
      router.push({
        name: 'notification-list',
      })
      notification_close_button.value.click()
    };

    const updateIsClick = (notification) => {
      const params = {
        notification_id: notification.id,
      };
      console.log("params", params);
      Promise.all([NotificationService.updateNotificationClick(params)]).then(
        (data) => {
          context.emit("getRecentNotification");
        }
      );
    };

    return {
      loadDataFromServer,
      baseUrl,
      noResult,
      message,
      makeNotificationRead,
      user,
      updateIsClick,
      sendToNotificationPage,
      notification_close_button,
    };
  },
});
