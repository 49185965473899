
import router from "@/router";
import { computed, defineComponent, onMounted, ref } from "vue";
import { Mutations } from "@/store/enums/StoreEnums";

import { useStore } from "vuex";
import MasterService from "@/core/services/car/MasterService";
export default defineComponent({
  name: "kt-quick-links-menu",
  components: {},
  setup() {
    const store = useStore();
    const userRoutes = computed(() => {
      return store.getters.getRouteList;
    });
    onMounted(() => {
      // MasterService.getBankDetails();
    });
    const navigateTo = async (gotoLink, activeTab) => {
      store.commit(Mutations.SET_QUICK_LINK_NAVIGATION, activeTab);
      
      if (activeTab === "cancellation") {
        await router.push({ name: gotoLink, query: { opt: 'reload' } });
      } else {
        await router.push({ name: gotoLink });
      }
    };
    const setViewBankDetails = () => {
      
      store.commit(Mutations.SET_VIEW_BANK_DETAILS, true);
    };
    return { userRoutes, navigateTo, setViewBankDetails };
  }
});
